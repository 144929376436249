angular.module('app.directives', []).directive('scrollbar', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      return element.scrollbar();
    }
  };
}).directive('paginationFor', function() {
  return {
    restrict: 'A',
    template: '<div class="d-flex jc-c row" ng-show="table.data.pages > 1"> <div class="jc-c gap-12 col-12 row ai-c"> <div class="col-12 col-md-3 col-lg-3 d-flex ai-c jc-c jc-lg-e"> <button class="pagination-btn"  ng-disabled="table.params.page == 1" ng-click="table.setPage(1)" >zurück</button> </div> <ul class="pagination jc-c gap-12 col-12 col-md-6 col-lg-5 row mb-0"> <li class="page-item" style="width: 42px; height: 42px" ng-repeat="page in table.pagination track by $index" ng-class="{active: table.params.page == page, disabled: !page}"> <a href class="page-link" ng-click="table.setPage(page)" ng-show="page">{{ page }}</a> <a href class="page-link" ng-hide="page">&hellip;</a> </li> </ul> <div class="page-item page-item-btn col-12 col-md-3 col-lg-3 d-flex jc-c jc-lg-s"> <button class="pagination-btn" ng-disabled="table.params.page == table.data.pages" ng-click="table.setPage(table.pagination.slice(-1))">weiter</button> </div> </div> </div>',
    replace: true,
    scope: {
      table: '=paginationFor'
    },
    controller: ['$scope', '$filter', function(self, $filter) {}]
  };
});
